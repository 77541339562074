import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cliente: {},
  clientes_domicilios: [],
}

export const clientesEditarSlice = createSlice({
  name: 'clientesEditar',
  initialState,
  reducers: {
    setCliente: (state, { payload }) => {
      state.cliente = payload;
    },
    setClientesDomicilios: (state, { payload }) => {
      state.cliente = payload;
    },
  },
})

export const clientesEditarActions = clientesEditarSlice.actions

export default clientesEditarSlice.reducer