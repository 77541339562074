import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  localidades: [], // se inicializa en el hook useUsuariosInit,
  total: undefined,
  loading: undefined,
  nuevoOpen: false,
  editarOpen: false,

  selectedLocalidad: undefined,
}

export const localidadesSlice = createSlice({
  name: 'localidades',
  initialState,
  reducers: {
    setLocalidades: (state, { payload }) => {
      state.localidades = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
  },
})

export const localidadesActions = localidadesSlice.actions

export default localidadesSlice.reducer