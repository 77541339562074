import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  titulares: [],
  loading: false,
  total: undefined,

  nuevoOpen: false,
  editarOpen: false,
  selectedTitular: undefined
}

const filialesTitularesSlice = createSlice({
  name: 'filialesTitulares',
  initialState,
  reducers: {
    setTitulares: (state, { payload }) => {
      state.titulares = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedTitular = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedTitular = undefined
    },
  },
})

export const filialesTitularesActions = filialesTitularesSlice.actions

export default filialesTitularesSlice.reducer