import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  domicilios: [],
  loading: false,
  total: undefined,
  nuevoOpen: false,

  editarOpen: false,
  selectedDomicilio: undefined
}

export const filialesDomiciliosSlice = createSlice({
  name: 'filialesDomicilios',
  initialState,
  reducers: {
    setDomicilios: (state, { payload }) => {
      state.domicilios = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedDomicilio = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedDomicilio = undefined
    },
  },
})

export const filialesDomiciliosActions = filialesDomiciliosSlice.actions

export default filialesDomiciliosSlice.reducer