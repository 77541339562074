import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  preciosAforos: [],
  pagination: {
    current: 1,
    limit: 10
  },
  total: undefined,
  loading: false,

  nuevoOpen: false,
  editarOpen: false,
  selectedPrecioAforo: undefined,
}

export const preciosAforosSlice = createSlice({
  name: 'preciosAforos',
  initialState,
  reducers: {
    setPreciosAforos: (state, { payload }) => {
      state.preciosAforos = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setPagination: (state, {payload}) => {
      state.pagination = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedPrecioAforo = payload
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
      state.selectedPrecioAforo = undefined
    },
  },
})

export const preciosAforosActions = preciosAforosSlice.actions

export default preciosAforosSlice.reducer