import { Form, Input, Modal, List, Typography } from "antd";
import { useState } from "react";
import useCambiarClave from "./useCambiarClave";
import { useNavigate } from "react-router-dom";
import { LABEL_ALIGN } from "constants";

const CambiarClave = () => {
  const [form] = Form.useForm();
  const { onFinish, loading } = useCambiarClave();
  const navigate = useNavigate();

  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
  };

  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
    isDifferentFromCurrent: false,
  });

  const passwordRequirements = [
    { label: "Al menos 8 caracteres", key: "minLength" },
    { label: "Una letra mayúscula", key: "hasUppercase" },
    { label: "Una letra minúscula", key: "hasLowercase" },
    { label: "Un número", key: "hasNumber" },
    { label: "Un carácter especial (@, $, !, %, *, ?, &)", key: "hasSpecialChar" },
    { label: "No puede ser igual a la clave actual", key: "isDifferentFromCurrent" },
  ];

  const validatePassword = (newPassword) => {
    const currentPassword = form.getFieldValue("clave");

    setPasswordValidation({
      minLength: newPassword.length >= 8,
      hasUppercase: /[A-Z]/.test(newPassword),
      hasLowercase: /[a-z]/.test(newPassword),
      hasNumber: /\d/.test(newPassword),
      hasSpecialChar: /[@$!%*?&#._-]/.test(newPassword),
      isDifferentFromCurrent: newPassword && newPassword !== currentPassword,
    });
  };

  const isValidPassword = Object.values(passwordValidation).every((val) => val);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      if (isValidPassword) {
        onFinish(values);
      }
    } catch (error) {
      console.log('error:', error)
    }
  };

  return (
    <Modal
      open
      title="Debe cambiar su clave"
      okText="Cambiar clave"
      onOk={handleSubmit}
      confirmLoading={loading}
      onCancel={() => navigate("/login")}
      width={600}
    >
      <Form form={form} {...layout} labelAlign={LABEL_ALIGN}>
        <Form.Item
          label="Clave actual"
          name="clave"
          rules={[{ required: true, message: "Por favor, ingrese su clave actual" }]}
        >
          <Input.Password />
        </Form.Item>
        <List
          style={{ marginBottom: 25 }}
          dataSource={passwordRequirements}
          renderItem={(item) => (
            <>
              <Typography.Text type={passwordValidation[item.key] ? "success" : "danger"}>
                {passwordValidation[item.key] ? "✔" : "✖"} {item.label}
              </Typography.Text><br />
            </>
          )}
        />
        <Form.Item
          label="Clave nueva"
          name="newclave"
          rules={[
            { required: true, message: "Por favor, ingrese la nueva clave" },
          ]}
        >
          <Input.Password onChange={(e) => validatePassword(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Confirmar clave nueva"
          name="confirmNewClave"
          dependencies={['newclave']}
          rules={[
            { required: true, message: "Por favor, confirme la nueva clave" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newclave') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Las claves no coinciden"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CambiarClave;
