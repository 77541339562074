import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filial: undefined,
  selectedTabKey: "datos"
}

export const filialesEditarSlice = createSlice({
  name: 'filialesEditar',
  initialState,
  reducers: {
    setFilial: (state, { payload }) => {
      state.filial = payload
    },
    setSelectedTabKey: (state, { payload }) => {
      state.selectedTabKey = payload
    },
  },
})

export const filialesEditarActions = filialesEditarSlice.actions

export default filialesEditarSlice.reducer