import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  roles: [],
  loading: undefined,
  nuevoOpen: false,
  editarOpen: false,

  selectedRol: undefined,
}

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedRol = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedRol = undefined
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    setRoles: (state, { payload }) => {
      state.roles = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
  },
})

export const rolesActions = rolesSlice.actions

export default rolesSlice.reducer