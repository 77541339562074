import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  comisiones: [],
  loading: false,
  total: undefined,

  nuevoOpen: false,
  editarOpen: false,
  selectedComision: undefined
}

export const filialesComisionesSlice = createSlice({
  name: 'filialesComisiones',
  initialState,
  reducers: {
    setComisiones: (state, { payload }) => {
      state.comisiones = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state) => {
      state.editarOpen = true
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedAgenda = undefined
    },
    setSelectedComision: (state, { payload }) => {
      state.selectedComision = payload
    },
  },
})

export const filialesComisionesActions = filialesComisionesSlice.actions

export default filialesComisionesSlice.reducer