import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  redespachantes: [],
  total: undefined,
  loading: undefined,
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true
  },
  sorting: undefined,
  localidadesAtendidasOpen: false,
  nuevoOpen: false,
  editarOpen: false,
  selectedRedespachante: undefined,
}

export const redespachantesSlice = createSlice({
  name: 'redespachantes',
  initialState,
  reducers: {
    setRedespachantes: (state, { payload }) => {
      state.redespachantes = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload
    },
    setSorting: (state, {payload}) => {
      state.sorting = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    openLocalidadesAtendidas: (state, { payload }) => {
      state.localidadesAtendidasOpen = true
      state.selectedRedespachante = payload
    },
    closeLocalidadesAtendidas: (state) => {
      state.localidadesAtendidasOpen = false
      state.selectedRedespachante = undefined
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedRedespachante = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedRedespachante = undefined
    },
  },
})

export const redespachantesActions = redespachantesSlice.actions

export default redespachantesSlice.reducer