import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  vehiculos: [],
  total: undefined,
  loading: undefined,
  nuevoOpen: false,
  editarOpen: false,

  selectedVehiculo: undefined,
}

export const vehiculosSlice = createSlice({
  name: 'vehiculos',
  initialState,
  reducers: {
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedVehiculo = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    setVehiculos: (state, { payload }) => {
      state.vehiculos = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
  },
})

export const vehiculosActions = vehiculosSlice.actions

export default vehiculosSlice.reducer