import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  preciosExcedentes: [],
  total: undefined,
  loading: false,

  nuevoOpen: false,
  editarOpen: false,
  selectedPrecioExcedente: undefined,
}

export const preciosExcedentesSlice = createSlice({
  name: 'preciosExcedentes',
  initialState,
  reducers: {
    setPreciosExcedentes: (state, { payload }) => {
      state.preciosExcedentes = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedPrecioExcedente = payload
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
      state.selectedPrecioExcedente = undefined
    },
  },
})

export const preciosExcedentesActions = preciosExcedentesSlice.actions

export default preciosExcedentesSlice.reducer