import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filialesTargetKeys: [],
  rolesTargetKeys: [],
  sistemasTargetKeys: []
}

export const usuariosEditarSlice = createSlice({
  name: 'usuariosEditar',
  initialState,
  reducers: {
    setFilialesTargetKeys: (state, { payload }) => {
      state.filialesTargetKeys = payload
    },
    setRolesTargetKeys: (state, { payload }) => {
      state.rolesTargetKeys = payload
    },
    setSistemasTargetKeys: (state, { payload }) => {
      state.sistemasTargetKeys = payload
    },
  },
})

export const usuariosEditarActions = usuariosEditarSlice.actions

export default usuariosEditarSlice.reducer