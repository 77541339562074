import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  contratistas: [],
  loading: false,
  total: undefined,

  nuevoOpen: false,
  editarOpen: false,
  selectedContratista: undefined
}

export const filialesContratistasSlice = createSlice({
  name: 'filialesContratistas',
  initialState,
  reducers: {
    setContratistas: (state, { payload }) => {
      state.contratistas = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedContratista = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedContratista = undefined
    },
  },
})

export const filialesContratistasActions = filialesContratistasSlice.actions

export default filialesContratistasSlice.reducer