import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  seguros: [],
  loading: false,
  total: undefined,

  nuevoOpen: false,
  editarOpen: false,
  selectedSeguro: undefined
}

export const filialesSegurosSlice = createSlice({
  name: 'filialesSeguros',
  initialState,
  reducers: {
    setSeguros: (state, { payload }) => {
      state.seguros = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedSeguro = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedSeguro = undefined
    },
  },
})

export const filialesSegurosActions = filialesSegurosSlice.actions

export default filialesSegurosSlice.reducer