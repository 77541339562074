import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';
import SidebarMenu from './SidebarMenu';
import useStyles from 'hooks/useStyles';

const Sidebar = ({ collapsed }) => {
  const { colorBgContainer, colorPrimary, paddingMD } = useStyles()
  const { Sider } = Layout;
  const [headerHeight, setHeaderHeight] = useState();

  useEffect(() => {
    var header = document.querySelector('.ant-layout-header');
    if (header) {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          const headerHeight = entry.contentRect.height;
          setHeaderHeight(headerHeight);
        }
      });
      resizeObserver.observe(header);

      return () => {
        resizeObserver.unobserve(header);
      };
    }
  }, []);

  const sidebarStyles = {
    background: colorBgContainer,
    minHeight: "100vh",
    position: "relative",
    overflow: 'hidden',
    className: "ant-layout-header"
  }

  const logoContainerStyles = {
    height: headerHeight,
    background: colorPrimary,
    display: "flex",
    alignItems: "center",
    gap: "6px",
    paddingLeft: paddingMD
  }
  const smallLogoStyles = {
    height: 50
  }
  const bigLogoStyles = {
    height: 50,
    display: collapsed ? "none" : "block",
  }

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={sidebarStyles}
      width={240}
    >
      <Link to="/" style={logoContainerStyles}>
        <img style={smallLogoStyles} alt='Credifin' src='/logo-small.png' />
        <img style={bigLogoStyles} src='/logo-big.png' alt='Credifin' />
      </Link>
      <SidebarMenu />
    </Sider >
  )
};

export default Sidebar;