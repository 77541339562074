import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  contratistas: [],
  total: undefined,
  loading: undefined,
  nuevoOpen: false,
  editarOpen: false,

  vehiculos: [],
  nuevoVehiculoOpen: false,
  editarVehiculoOpen: false,
  selectedVehiculo: undefined,

  selectedContratista: undefined,
}

export const contratistasSlice = createSlice({
  name: 'contratistas',
  initialState,
  reducers: {
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedContratista = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openNuevoVehiculo: (state) => {
      state.nuevoVehiculoOpen = true
    },
    closeNuevoVehiculo: (state) => {
      state.nuevoVehiculoOpen = false
    },
    openEditarVehiculo: (state, { payload }) => {
      state.editarVehiculoOpen = true
      state.selectedVehiculo = payload
    },
    closeEditarVehiculo: (state) => {
      state.editarVehiculoOpen = false
      state.selectedVehiculo = undefined
    },
    setVehiculos: (state, { payload }) => {
      state.vehiculos = payload
    },
    setContratistas: (state, { payload }) => {
      state.contratistas = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
  },
})

export const contratistasActions = contratistasSlice.actions

export default contratistasSlice.reducer