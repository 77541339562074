import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  preciosDistancias: [],
  nuevoOpen: false,
  asignarFrecuenciaOpen: false,
  selectedPrecioDistancia: undefined,
  loading: false,
  total: undefined
}

export const preciosDistanciasSlice = createSlice({
  name: 'preciosDistancias',
  initialState,
  reducers: {
    openNuevo: (state, { payload }) => {
      state.nuevoOpen = true
      state.selectedPrecioDistancia = payload
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
      state.selectedPrecioDistancia = undefined
    },
    openAsignarFrecuencia: (state, { payload }) => {
      state.asignarFrecuenciaOpen = true
      state.selectedPrecioDistancia = payload
    },
    closeAsignarFrecuencia: (state) => {
      state.asignarFrecuenciaOpen = false
      state.selectedPrecioDistancia = undefined
    },
    setPreciosDistancias: (state, { payload }) => {
      state.preciosDistancias = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
  },
})

export const preciosDistanciasActions = preciosDistanciasSlice.actions

export default preciosDistanciasSlice.reducer