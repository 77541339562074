import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  contactos: [],
  loading: false,
  total: undefined,

  nuevoOpen: false,
  editarOpen: false,
  contacto: undefined
}

export const filialesContactosSlice = createSlice({
  name: 'filialesContactos',
  initialState,
  reducers: {
    setContactos: (state, { payload }) => {
      state.contactos = payload
    },
    setContacto: (state, { payload }) => {
      state.contacto = payload
    },

  },
})

export const filialesContactosActions = filialesContactosSlice.actions

export default filialesContactosSlice.reducer