import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  acuerdo: undefined,
  loading: undefined,

  clientesAutorizadosOpen: false,

}

const acuerdoDetalleSlice = createSlice({
  name: 'acuerdoDetalle',
  initialState,
  reducers: {
    openClientesAutorizados: (state) => {
      state.clientesAutorizadosOpen = true
    },
    closeClientesAutorizados: (state) => {
      state.clientesAutorizadosOpen = false
    },
    setAcuerdo: (state, { payload }) => {
      state.acuerdo = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
  },
})

export const acuerdoDetalleActions = acuerdoDetalleSlice.actions

export default acuerdoDetalleSlice.reducer