import { useState } from "react"
import useGetFiliales from "hooks/useGetFiliales"
import { filterOption } from "utils";
import { Drawer, Select, Tooltip } from "antd"
import { EditOutlined } from "@ant-design/icons"
import useCambiarFilial from "./useCambiarFilial";

const CambiarFilial = ({ usuario }) => {
  const { filialesOptions } = useGetFiliales({ conCasaCentral: 'SI' });
  const [open, setOpen] = useState(false);
  const { handleCambiarFilial, loading } = useCambiarFilial();

  const onChange = async (filialId) => {
    handleCambiarFilial(usuario?.id, filialId)
  };

  return (
    <>
      <Tooltip title="Cambiar filial" mouseLeaveDelay={0}>
        <EditOutlined onClick={() => setOpen(true)} />
      </Tooltip>
      <Drawer
        title="Cambiar filial"
        onClose={() => setOpen(false)}
        open={open}
        placement="top"
        height={140}
      >
        <Select
          value={usuario?.filiales_id}
          options={filialesOptions}
          placeholder="Seleccionar"
          filterOption={filterOption}
          onChange={onChange}
          showSearch
          style={{ width: '100%' }}
          loading={loading}
        />
      </Drawer>
    </>
  );
};


export default CambiarFilial;