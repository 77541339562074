import { AUTH_TOKEN, IS_FILIAL_SELECTED } from "constants/auth"
import { useNavigate } from "react-router-dom"

const useLogout = () => {
  const navigate = useNavigate()
  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN)
    localStorage.removeItem(IS_FILIAL_SELECTED);
    navigate("/login")
  }
  return {
    logout
  }
}

export default useLogout