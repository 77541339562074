import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  precio: undefined,
}

export const precioDetalleSlice = createSlice({
  name: 'precioDetalle',
  initialState,
  reducers: {
    setPrecio: (state, { payload }) => {
      state.precio = payload
    },
  },
})

export const precioDetalleActions = precioDetalleSlice.actions

export default precioDetalleSlice.reducer