import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  zonas: [],

  configuracionOpen: undefined,
  
  // zona a editar
  zonaEditar: undefined,
  // zona a borrar
  zonaBorrar: undefined,

  // coordenadas de la zona a crear
  zonaCrear: undefined,

  zonasOptions: undefined
}

export const filialesCoberturaSlice = createSlice({
  name: 'filialesCoberturas',
  initialState,
  reducers: {
    setZonas: (state, {payload}) => {
      state.zonas = payload
    }, 
    setConfiguracionOpen: (state, { payload }) => {
      state.configuracionOpen = payload
    },
    setZonaEditar: (state, { payload }) => {
      state.zonaEditar = payload
    },
    setZonaBorrar: (state, { payload }) => {
      state.zonaBorrar = payload
    },
    setZonaCrear: (state, { payload }) => {
      state.zonaCrear = payload
    },
    setZonasOptions: (state, { payload }) => {
      state.zonasOptions = payload
    }
  },
})

export const filialesCoberturaActions = filialesCoberturaSlice.actions

export default filialesCoberturaSlice.reducer