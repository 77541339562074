import { useState } from 'react';
import { message } from 'antd';
import { cambiarFilial } from './api';
import { IS_FILIAL_SELECTED } from 'constants/auth';

const useCambiarFilial = (origen) => {
  const [loading, setLoading] = useState(false);

  const handleCambiarFilial = async (usuarioID, filialID) => {
    setLoading(true);
    try {
      const response = await cambiarFilial({
        id: usuarioID,
        filiales_id: filialID,
      });

      if (response.status === "success") {

        window.location.reload(false);

        if (origen === 'CAMBIAR_FILIAL') {
          localStorage.setItem(IS_FILIAL_SELECTED, true);
        }
      } else {
        message.error(response.message, 7);
      }
    } catch (e) {
      message.error(e.message || e, 7);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleCambiarFilial,
    loading,
  };
};

export default useCambiarFilial;
