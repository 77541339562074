import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  precios: [],
  total: undefined,
  loading: undefined,
  nuevoOpen: false,
  editarOpen: false,

  duplicarListaOpen: false,
  selectedPrecio: undefined,
}

export const preciosSlice = createSlice({
  name: 'precios',
  initialState,
  reducers: {
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedPrecio = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedPrecio = undefined
    },
    openDuplicarLista: (state, { payload }) => {
      state.duplicarListaOpen = true
      state.selectedPrecio = payload
    },
    closeDuplicarLista: (state) => {
      state.duplicarListaOpen = false
      state.selectedPrecio = undefined
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    setPrecios: (state, { payload }) => {
      state.precios = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
  },
})

export const preciosActions = preciosSlice.actions

export default preciosSlice.reducer