import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  exclusiones: [],
  loading: false,
  total: undefined,

  nuevoOpen: false,
  editarOpen: false,
  selectedExclusion: undefined
}

export const filialesExclusionesSlice = createSlice({
  name: 'filialesExclusiones',
  initialState,
  reducers: {
    setExclusiones: (state, { payload }) => {
      state.exclusiones = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedExclusion = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedExclusion = undefined
    },
  },
})

export const filialesExclusionesActions = filialesExclusionesSlice.actions

export default filialesExclusionesSlice.reducer