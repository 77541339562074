import { CheckOutlined, ClockCircleOutlined, CloseOutlined, InfoCircleOutlined, SwapOutlined, WarningOutlined } from "@ant-design/icons"

const DEMO = {
  //  link: 'javascript:;', // javascript:;
  home: '#/app/dashboard',
  home2: '/app/dashboard',
  productLink: 'https://themeforest.net/item/ant-design-reactjs-admin-with-bootstrap/22194157',
  user: 'Lisa',
  login: '#/user/login',
  signUp: '#/user/sign-up',
  forgotPassword: '#/user/forgot-password',
}

// header links
DEMO.headerLink = {
  profile: '#/app/page/about',
  about: '#/app/page/about',
  help: '#/app/page/contact',
  signOut: '#/user/login' // redirect login page
}

export default DEMO;

export const dias = [
  { name: 'lunes', label: 'Lunes' },
  { name: 'martes', label: 'Martes' },
  { name: 'miercoles', label: 'Miércoles' },
  { name: 'jueves', label: 'Jueves' },
  { name: 'viernes', label: 'Viernes' },
  { name: 'sabado', label: 'Sábado' },
  { name: 'domingo', label: 'Domingo' }
];

export const estados = [
  {
    id: 1,
    icon: <CheckOutlined />,
    state: 'AUTORIZADO',
    title: 'Autorizado',
    color: '#49aa19',
  }, {
    id: 2,
    icon: <InfoCircleOutlined />,
    state: 'INCOMPLETO',
    title: 'Incompleto',
    color: '#E3651D',
  }, {
    id: 3,
    icon: <ClockCircleOutlined />,
    state: 'PENDIENTE',
    title: 'Pendiente',
    color: '#1668dc',
  }, {
    id: 4,
    icon: <SwapOutlined />,
    state: 'EN_TRAMITE',
    title: 'En trámite',
    color: 'gray',
  }, {
    id: 5,
    icon: <CloseOutlined />,
    state: 'RECHAZADO',
    title: 'Rechazado',
    color: '#dc4446',
  }, {
    id: 6,
    icon: <WarningOutlined />,
    state: 'VENCIDO',
    title: 'Vencido',
    color: '#dc4446',
  }, {
    id: 7,
    icon: <CheckOutlined />,
    state: 'COMPLETADO',
    title: 'Completado',
    color: '#49aa19',
  },
];

export const documentos = {
  CONTRATO_AGENCIA: 1,
  DDJJ_SECRETO_POSTAL_PERSONAL: 2,
  DDJJ_SECRETO_INEXISTENCIA_PERSONAL: 4,
  DDJJ_SECRETO_INEXISTENCIA_CONTRATISTA: 5,
  DDJJ_SECRETO_POSTAL_TITULAR: 6,
};

export const TIPOS_DOMICILIOS = {
  CLIENTE: 1,
  DOMICILIO: 2,
  PUNTOS_ENTREGA: 3,
  ENTREGA_RETIRO: 4,
};

export const TIPOS_DOMICILIOS_TITULOS = {
  1: 'cliente',
  2: 'domicilio',
  3: 'puntos de entrega',
  4: 'entrega/retiro',
};

export const ARTICULOS = {
  FLETE_DISTANCIA_PESO: 119,
  FLETE_DISTANCIA_VOLUMEN: 126,
};

export const ACUERDOS_TIPOS = {
  FLETE_DISTANCIA_PESO: 1,
  FLETE_DISTANCIA_VOLUMEN: 2,
  ESPECIFICO: 3,
  VALOR_DECLARADO: 4,
  PERSONALIZADO: 5,
};

export const ACUERDOS_TITLE = {
  plural: 'Bonificaciones',
  singular: 'Bonificación',
};

export const UNIDADES_MEDIDAS = {
  BULTOS: 3,
  VOLUMEN: 4,
  PESO: 5,
  PESO_VOLUMETRICO: 8,
};

export const AUTORIZACIONES_TIPOS = {
  ACUERDOS: 1,
  NOTAS_CREDITO: 2,
  FACTURAS: 3,
};

export const AUTORIZACIONES_ESTADOS = {
  PENDIENTE: 1,
  AUTORIZADO: 2,
  RECHAZADO: 3,
};

export const SUBRUBROS = {
  FLETE_DISTANCIA: 15,
};

export const ERRORES_PERMITIDOS_AFIP = [
  "No responde al requerimiento",
  "La CUIT registra pendiente la declaracion de los datos de contacto (mail y teléfono) y/o su asociacion al Domicilio Fiscal Electronico."
];

export const VEHICULOS_SALIDAS_ESTADOS = {
  PENDIENTE: "Pendiente de Salida",
  EN_CAMINO: "En camino",
  COMPLETADO: "Recorrido terminado",
};