import { theme } from "antd"

const useStyles = () => {
  const { token } = theme.useToken();
  
  return {
    ...token,
    fontWeightLight: 300,
    headerItemHeight: 64,
    smallItemStyle: {
      width: "80px"
    },
    mediumItemStyle: {
      width: "220px"
    },
    bigItemStyle: {
      width: "300px"
    }
  }
}

export default useStyles