import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  localidades: [],
  loading: false,
  total: undefined,
  redespacho: "NO",

  nuevoOpen: false,

  editarOpen: false,
  selectedLocalidad: undefined
}

export const filialesLocalidadesSlice = createSlice({
  name: 'filialesLocalidades',
  initialState,
  reducers: {
    setLocalidades: (state, { payload }) => {
      state.localidades = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setRedespacho: (state, { payload }) => {
      state.redespacho = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state) => {
      state.editarOpen = true
    },
    closeEditar: (state) => {
      state.editarOpen = false
    },
    setSelectedLocalidad: (state, { payload }) => {
      state.selectedLocalidad = payload
    },
  },
})

export const filialesLocalidadesActions = filialesLocalidadesSlice.actions

export default filialesLocalidadesSlice.reducer