import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  firmantes: [],
  loading: false,
  total: undefined,

  nuevoOpen: false,
  editarOpen: false,
  selectedFirmante: undefined
}

const filialesFirmantesSlice = createSlice({
  name: 'filialesFirmantes',
  initialState,
  reducers: {
    setFirmantes: (state, { payload }) => {
      state.firmantes = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedFirmante = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedFirmante = undefined
    },
  },
})

export const filialesFirmantesActions = filialesFirmantesSlice.actions

export default filialesFirmantesSlice.reducer