import api from "api";
import useQuery from "hooks/useQuery";
import { useState } from "react";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "utils";
import DynamicIcon from "./DynamicIcon";

const useSidebarMenuItems = () => {
  const [menuItems, setMenuItems] = useState([])
  useQuery({
    queryFn: api.auth.getAccesos,
    onSuccess: res => {
      const parsedAccesos = res.data.map((a, i) => {
        const children = a.menu_items.map((item, index) => ({
          key: item.item + index,
          label: (
            <Link to={item.link}>
              {capitalizeFirstLetter(item.item)}
            </Link>
          ),
        }))
        const label = a.link ?
          <Link to={a.link} style={{ color: "black" }}>
            {capitalizeFirstLetter(a.menu)}
          </Link> :
          capitalizeFirstLetter(a.menu)
        return {
          key: i,
          label: label,
          children: children,
          icon: <DynamicIcon icon={a.icono} />
        }
      })

      setMenuItems(parsedAccesos)
    }
  })
  return {
    menuItems,
  }
}

export default useSidebarMenuItems
