import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  vehiculos: [],
  loading: false,
  total: undefined,

  nuevoOpen: false,
  editarOpen: false,
  tarjetaVerdeOpen: false,
  segurosOpen: false
}

export const filialesVehiculosSlice = createSlice({
  name: 'filialesVehiculos',
  initialState,
  reducers: {
    setVehiculos: (state, { payload }) => {
      state.vehiculos = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedVehiculo = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedVehiculo = undefined
    },
    openTarjetaVerde: (state, { payload }) => {
      state.tarjetaVerdeOpen = true
      state.selectedVehiculo = payload
    },
    closeTarjetaVerde: (state) => {
      state.tarjetaVerdeOpen = false
      state.selectedVehiculo = undefined
    },
    openSeguros: (state, { payload }) => {
      state.segurosOpen = true
      state.selectedVehiculo = payload
    },
    closeSeguros: (state) => {
      state.segurosOpen = false
      state.selectedVehiculo = undefined
    },
  },
})

export const filialesVehiculosActions = filialesVehiculosSlice.actions

export default filialesVehiculosSlice.reducer