import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accesos: [],
  total: undefined,
  loading: undefined,
  agregarOpen: false,
  editarOpen: false,

  selectedAcceso: undefined,
}

export const rolesAccesosSlice = createSlice({
  name: 'rolesAccesos',
  initialState,
  reducers: {
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedAcceso = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedAcceso = undefined
    },
    openAgregar: (state) => {
      state.agregarOpen = true
    },
    closeAgregar: (state) => {
      state.agregarOpen = false
    },
    setAccesos: (state, { payload }) => {
      state.accesos = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
  },
})

export const rolesAccesosActions = rolesAccesosSlice.actions

export default rolesAccesosSlice.reducer