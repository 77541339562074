import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  latLng: undefined,
  localidad: undefined,
  provincia: undefined,
  mapa: false,
  zoom: 15,
  redespacho: false
}

export const localidadFormSlice = createSlice({
  name: 'localidadForm',
  initialState,
  reducers: {
    setLatLng: (state, { payload }) => {
      state.latLng = payload
    },
    setLocalidad: (state, { payload }) => {
      state.localidad = payload
    },
    setProvincia: (state, { payload }) => {
      state.provincia = payload
    },
    setZoom: (state, { payload }) => {
      state.zoom = payload
    },
    setMapa: (state, { payload }) => {
      state.mapa = payload
    },
    setRedespacho: (state, { payload }) => {
      state.redespacho = payload
    },
    
  },
})

export const localidadFormActions = localidadFormSlice.actions

export default localidadFormSlice.reducer