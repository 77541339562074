import { configureStore } from '@reduxjs/toolkit'
import clientesEditarSlice from 'routes/clientes/Editar/clientesEditarSlice'
import contratistasSlice from 'routes/contratistas/contratistasSlice'
import filialesCoberturaSlice from 'routes/filialesEditar/components/CoberturaTab/filialesCoberturaSlice'
import filialesContactosSlice from 'routes/filialesEditar/components/ContactosTab/filialesContactosSlice'
import filialesDistanciasSlice from 'routes/filialesEditar/components/DistanciasTab/filialesDistanciasSlice'
import filialesDomiciliosSlice from 'routes/filialesEditar/components/DomiciliosTab/filialesDomiciliosSlice'
import filialesExclusionesSlice from 'routes/filialesEditar/components/ExclusionesTab/filialesExclusionesSlice'
import filialesLocalidadesSlice from 'routes/filialesEditar/components/LocalidadesTab/filialesLocalidadesSlice'
import filialesSegurosSlice from 'routes/filialesEditar/components/SegurosTab/filialesSegurosSlice'
import filialesZonasSlice from 'routes/filialesEditar/components/ZonasTab/filialesZonasSlice'
import filialesEditarSlice from 'routes/filialesEditar/filialesEditarSlice'
import localidadFormSlice from 'routes/localidades/components/LocalidadForm/localidadFormSlice'
import localidadesSlice from 'routes/localidades/localidadesSlice'
import rolesAccesosSlice from 'routes/roles/components/AccesosTable/rolesAccesosSlice'
import rolesSlice from 'routes/roles/rolesSlice'
import usuariosEditarSlice from 'routes/usuarios/components/Editar/usuariosEditarSlice'
import vehiculosSlice from 'routes/vehiculos/vehiculosSlice'
import vehiculosTiposSlice from 'routes/vehiculosTipos/vehiculosTiposSlice'
import generalSlice from './generalSlice'
import preciosSlice from 'routes/preciosAxel/preciosSlice'
import precioDetalleSlice from 'routes/precioDetalle/precioDetalleSlice'
import preciosDistanciasSlice from 'routes/precioDetalle/PreciosDistancias/preciosDistanciasSlice'
import preciosExcedentesSlice from 'routes/precioDetalle/Excedentes/excedentesSlice'
import preciosAforosSlice from 'routes/precioDetalle/Aforos/aforosSlice'
import preciosAdicionalesSlice from 'routes/precioDetalle/Adicionales/adicionalesSlice'
import preciosSegurosSlice from 'routes/precioDetalle/Seguros/segurosSlice'
import subrubrosSlice from 'routes/subrubros/subrubrosSlice'
import filialesTitularesSlice from 'routes/filialesEditar/components/TitularesTab/filialesTitularesSlice'
import filialesFirmantesSlice from 'routes/filialesEditar/components/FirmantesTab/filialesFirmantesSlice'
import preciosFrecuenciasSlice from 'routes/precioDetalle/Frecuencias/frecuenciasSlice'
import filialesComisionesSlice from 'routes/filialesEditar/components/ContratoTab/ComisionesTab/filialesComisionesSlice'
import acuerdoDetalleSlice from 'routes/acuerdoDetalle/acuerdoDetalleSlice'
import redespachantesSlice from 'routes/redespachantes-old/redespachantesSlice'
import filialesContratistasSlice from 'routes/filialesEditar/components/ContratistasTab/filialesContratistasSlice'
import filialesVehiculosSlice from 'routes/filialesEditar/components/VehiculosTab/filialesVehiculosSlice'
// import filialesPersonalSlice from 'routes/filialesEditar/components/PersonalTab-old/filialesPersonalSlice'
// import filialesDocumentacionSlice from 'routes/filialesEditar/components/Documentacion/filialesDocumentacionSlice'

export const store = configureStore({
  reducer: {
    general: generalSlice,

    usuariosEditar: usuariosEditarSlice,

    clientesEditar: clientesEditarSlice,

    filialesEditar: filialesEditarSlice,
    filialesCobertura: filialesCoberturaSlice,
    filialesZonas: filialesZonasSlice,
    filialesDomicilios: filialesDomiciliosSlice,
    filialesLocalidades: filialesLocalidadesSlice,
    filialesContactos: filialesContactosSlice,
    filialesComisiones: filialesComisionesSlice,
    filialesExclusiones: filialesExclusionesSlice,
    filialesSeguros: filialesSegurosSlice,
    filialesDistancias: filialesDistanciasSlice,
    filialesTitulares: filialesTitularesSlice,
    filialesFirmantes: filialesFirmantesSlice,
    filialesContratistas: filialesContratistasSlice,
    filialesVehiculos: filialesVehiculosSlice,
    // filialesPersonal: filialesPersonalSlice,
    // filialesDocumentacion: filialesDocumentacionSlice,

    localidades: localidadesSlice,
    localidadForm: localidadFormSlice,

    roles: rolesSlice,
    rolesAccesos: rolesAccesosSlice,

    acuerdoDetalle: acuerdoDetalleSlice,
    contratistas: contratistasSlice,
    vehiculos: vehiculosSlice,
    vehiculosTipos: vehiculosTiposSlice,

    precios: preciosSlice,
    precioDetalle: precioDetalleSlice,
    preciosDistancias: preciosDistanciasSlice,
    preciosExcedentes: preciosExcedentesSlice,
    preciosAforos: preciosAforosSlice,
    preciosAdicionales: preciosAdicionalesSlice,
    preciosSeguros: preciosSegurosSlice,
    preciosFrecuencias: preciosFrecuenciasSlice,

    subrubros: subrubrosSlice,
    redespachantes: redespachantesSlice,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["filialesCoberturas/setZonaCrear", "filialesCoberturas/setZonas", "filialesZonas/setZonaCrear", "filialesZonas/setZonas",],
        ignoredPaths: ["filialesCobertura.zonaCrear", "filialesCobertura.zonas", "filialesZonas.zonaCrear", "filialesZonas.zonas",]
      }
    })
  }
})