import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  vehiculosTipos: [],
  total: undefined,
  loading: undefined,
  nuevoOpen: false,
  editarOpen: false,

  selectedVehiculoTipo: undefined,
}

export const vehiculosTiposSlice = createSlice({
  name: 'vehiculosTipos',
  initialState,
  reducers: {
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedVehiculoTipo = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    setVehiculosTipos: (state, { payload }) => {
      state.vehiculosTipos = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
  },
})

export const vehiculosTiposActions = vehiculosTiposSlice.actions

export default vehiculosTiposSlice.reducer