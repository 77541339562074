import { ConfigProvider, DatePicker, Modal, Select, theme } from "antd";
import esES from "antd/locale/es_ES";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect } from "react";
import { Provider, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { store } from './redux/store';
import router from './router';
import './index.css';

dayjs.extend(utc);

const App = () => {
  const { defaultAlgorithm, darkAlgorithm, compactAlgorithm } = theme;
  const tema = useSelector(state => state.general.tema);
  const algorithms = [
    defaultAlgorithm,
    darkAlgorithm,
    compactAlgorithm,
  ];

  useEffect(() => {
    Modal.defaultProps = {
      maskClosable: false
    };
    Select.defaultProps = {
      notFoundContent: false
    };
    DatePicker.defaultProps = {
      format: "DD/MM/YYYY"
    };
  }, []);

  return (
    <ConfigProvider
      locale={esES}
      theme={{
        algorithm: algorithms[tema],
        token: {
          colorPrimary: "#2d3eb3"
        }
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  )
};

const AppWrapper = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default AppWrapper;
