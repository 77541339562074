import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  preciosFrecuencias: [],
  pagination: {
    current: 1,
    limit: 10
  },
  total: undefined,
  loading: false,

  nuevoOpen: false,
  editarOpen: false,
  selectedPrecioFrecuencia: undefined,
}

export const preciosFrecuenciasSlice = createSlice({
  name: 'preciosFrecuencias',
  initialState,
  reducers: {
    setPreciosFrecuencias: (state, { payload }) => {
      state.preciosFrecuencias = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setPagination: (state, {payload}) => {
      state.pagination = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedPrecioFrecuencia = payload
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
      state.selectedPrecioFrecuencia = undefined
    },
  },
})

export const preciosFrecuenciasActions = preciosFrecuenciasSlice.actions

export default preciosFrecuenciasSlice.reducer