import { Menu } from "antd"
import React from "react"
import { useLocation } from "react-router-dom"
import useSidebarMenuItems from "./useSidebarMenuItems"

const SidebarMenu = () => {
  const location = useLocation()
  const { menuItems } = useSidebarMenuItems()

  return (
      <Menu
        items={menuItems}
        mode="inline"
        selectedKeys={[location.pathname]}
        style={{ height: "100%" }}
      />
  )
};

export default React.memo(SidebarMenu);