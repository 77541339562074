export const RULE_REQUIRED = {
  required: true,
  message: 'Este campo es obligatorio',
};

export const RULE_ONLY_NUMBERS = {
  pattern: new RegExp("^[0-9]*$"),
  message: "Este campo solo acepta números"
}

export const LABEL_ALIGN = "left";

export const DATE_FORMAT = 'DD/MM/YYYY';

export const WHATSAPP_COLOR = '#25D366';

export const CONTACT_ICON_SIZE = 18;

export const CASA_CENTRAL_ID = 8;

export const MAIL_COLOR = '#7091F5';

export const PENDIENTE_COLOR = '#FF9843';

export const TYPE_TAB = '';

export const PRECIOS_TIPOS = {
  LISTA_GENERAL: 1,
  ACUERDOS: 2,
  LISTA_GENERAL_REDESPACHOS: 3,
  REDESPACHOS: 4,
};

export const PRECIOS_CONFIG = {
  [PRECIOS_TIPOS.LISTA_GENERAL]: {
    title: 'Lista de precios de ',
    to: '/precios',
    breadcrumbTitle: 'Lista de precios',
  },
  [PRECIOS_TIPOS.ACUERDOS]: {
    title: 'Bonificación de precios de ',
    to: '/bonificaciones',
    breadcrumbTitle: 'Bonificaciones',
  },
};

export const FILIALES_ACUERDOS_TITLE = "Filiales destino donde se aplica la bonificación";

export const LABEL_HEREDAR = 'Puede usar acuerdo cuando es remitente';

export const CONFIRMATION_MESSAGES = {
  DELETE_CONFIRMATION: "¿Está seguro de que desea eliminar?",
};